import React, {useEffect} from 'react';
import {format} from 'date-fns';
import {useDispatch, useSelector} from "react-redux";

import {getAccomplishments} from "../../actions/achievement";
import AchiError from "./AchiError";
import AlertIcon from "../../assets/alert-orange.svg";

function DayView() {

  const dispatch = useDispatch();
  const {list, error} = useSelector((s) => s.accomplishmentReducer);

  useEffect(() => {
    dispatch(getAccomplishments());
  }, [dispatch]);

  return (
    <div className={"offset-md-0 col-md-12 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6 mainList"}>
      <div className={"warning row"}>
        <div className={"primary"}>
          <div className={"icon"}>
            <img alt="alert" src={AlertIcon}/>
          </div>
          <div className={"text"}>
            <p>Cette page est en cours développement</p>
          </div>
        </div>
      </div>
      <br/>
      {error &&
        <AchiError/>
      }
      {
        list.map((acc) => <div className={"accomplishment"}>
          <p className={"main"}>Vous avez {acc.status === "VALIDATED" ? "terminé" : "annulé"} <strong className={acc.status === "VALIDATED" ? "done" : "canceled"}>{acc.goal.title}</strong></p>
          <p className={"sub"}>le {format(new Date(acc.createdAt), 'dd/MM/yyyy')} à {format(new Date(acc.createdAt), 'HH:mm')}</p>
        </div>)
      }
    </div>
  );
}

export default DayView;
