import React from 'react';

import "./checkbox.css";

function Checkbox(props) {
  const randomId = Math.random();
  return (
    <span>
      <input type="checkbox" id={randomId} className={props.size} checked={props.checked} value={props.value} onClick={props.onClick}/>
      <label htmlFor={randomId}>{props.children}</label>
    </span>
  );
}

export default Checkbox;
