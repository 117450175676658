import React from 'react';

function AchiError() {

  return (
    <div className={"offset-md-0 col-md-12 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6 mainList"}>
      <h2>Something went wrong...</h2>
      <img src="https://media0.giphy.com/media/dX1K0xlyYZ4jFXfaMC/giphy.gif" alt="error"/>
    </div>
  );
}

export default AchiError;
