import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import * as R from "ramda";

import {
  validateAchievement,
  removeAchievement,
  archiveAchievement,
  unarchiveAchievement
} from "../../actions/achievement";

import DeleteIcon from '../../assets/delete.svg';
import EditIcon from '../../assets/edit.svg';
import ArchiveIcon from '../../assets/archive.svg';
import UnarchiveIcon from '../../assets/unarchive.svg';

import './styles.css';
import AchiNew from "./AchiNew";
import Checkbox from "../ui/Checkbox";
import AchiTag from "./AchiTag";
import Button from "../ui/Button";

function AchiItem(props) {

  const dispatch = useDispatch();

  const {isDaily, isWeekly, isMonthly, isOneShot, uuid, title, status, description} = props.item;

  const [isDone, setIsDone] = useState(props.item.isDone);
  const [isEditing, setIsEditing] = useState(false);

  const remove = (uuid) => {
    dispatch(removeAchievement(uuid));
  };

  const archive = (uuid) => {
    dispatch(archiveAchievement(uuid));
  };

  const unarchive = (uuid) => {
    dispatch(unarchiveAchievement(uuid));
  };

  const closeModal = () => {
    dispatch({type: "RESET_MODAL"});
  };

  const openModal = () => {
    dispatch({
      type: "SET_MODAL",
      content:
        <div>
          <h1>{title}</h1>
          <div className={"row"}>
            <div className={"col-12"}>
              <Button
                onClick={() => {
                  dispatch(validateAchievement(uuid, !isDone));
                  setIsDone(!isDone);
                  closeModal();
                }}>
                Marquer comme {isDone ? "non" : ""} réalisé
              </Button>
            </div>
            <div className={"col-12"}>
              <Button
                onClick={() => {
                  setIsEditing(!isEditing);
                  closeModal();
                }}>
                Modifier
              </Button>
            </div>
            <div className={"col-12"}>
              {status === "ARCHIVED" &&
              <Button
                onClick={() => {
                  unarchive(uuid);
                  closeModal();
                }}>
                Désarchiver
              </Button>
              }
              {status !== "ARCHIVED" &&
              <Button
                onClick={() => {
                  archive(uuid);
                  closeModal();
                }}>
                Archiver
              </Button>
              }
            </div>
            <div className={"col-12"}>
              <Button
                onClick={() => {
                  remove(uuid);
                  closeModal();
                }}>
                Supprimer
              </Button>
            </div>
          </div>
        </div>
    });
  };

  const handleCheckbox = () => {
    dispatch(validateAchievement(uuid, !isDone));
    setIsDone(!isDone);
  };

  const hasFooter = isDaily || isWeekly || isMonthly || status === "ARCHIVED";

  if (isEditing) {
    return <div className={"editing"}>
      <AchiNew uuid={uuid} title={title} description={description} isDaily={isDaily} isWeekly={isWeekly}
               isMonthly={isMonthly} isOneShot={isOneShot} stopEdition={() => setIsEditing(false)}/>
    </div>
  }

  return (
    <div>
      <div className={`achievement-wrapper hideOnLargeScreen`} onClick={() => openModal()}>
        <div className={`achievement ${isDone ? 'validated' : ''}  ${props.isDragging ? "dragging" : ""}`}>
          <div className={`topbar ${hasFooter ? "withFooter" : ""}`}>
            <div className={"titleBlock"}>
              <h3>{title}</h3>
            </div>
          </div>
          {hasFooter &&
          <div className="footer">
            {isDaily && <AchiTag label={"Quotidienne"}/>}
            {isWeekly && <AchiTag label={"Hebdomadaire"}/>}
            {isMonthly && <AchiTag label={"Mensuelle"}/>}
            {status === "ARCHIVED" && <AchiTag type={"archived"} label={"Archivée"}/>}
          </div>
          }
        </div>
      </div>
      <div className={`achievement-wrapper hideOnSmallScreen`}>
        <div className={`achievement ${isDone ? 'validated' : ''}  ${props.isDragging ? "dragging" : ""}`}>
          <div className={"topBar"}>
            <div className={"actions left"}>
              <Checkbox size="big" checked={isDone} onClick={handleCheckbox}/>
            </div>
            <div className={"titleBlock"}>
              <h3>{title}</h3>
            </div>
            <div className={"actions right"}>
              <img className={"edit"} alt="edit" src={EditIcon} onClick={() => setIsEditing(!isEditing)}/>
              {status === "ARCHIVED" &&
              <img className={"unarchive"} alt="unarchive" src={UnarchiveIcon} onClick={() => unarchive(uuid)}/>
              }
              {status !== "ARCHIVED" &&
              <img className={"archive"} alt="archive" src={ArchiveIcon} onClick={() => archive(uuid)}/>
              }
              <img className={"delete"} alt="delete" src={DeleteIcon} onClick={() => remove(uuid)}/>
            </div>
          </div>
          {description &&
          <p className={"description"}>{description}</p>
          }
          {hasFooter &&
          <div className="footer">
            {isDaily && <AchiTag label={"Quotidienne"}/>}
            {isWeekly && <AchiTag label={"Hebdomadaire"}/>}
            {isMonthly && <AchiTag label={"Mensuelle"}/>}
            {status === "ARCHIVED" && <AchiTag type={"archived"} label={"Archivée"}/>}
          </div>
          }
        </div>
      </div>
    </div>
  );
}

export default AchiItem;
