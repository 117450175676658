import * as R from "ramda";

const initialState = {
  list: [],
  loading: false,
  error: false,
};

const AccomplishmentReducer = function(previousState = initialState, action) {
  const newState = R.clone(previousState);
  switch (action.type) {
    case "REQUEST_ACCOMPLISHMENTS":
      return R.pipe(
        R.assoc("error", false),
        R.assoc("loading", true)
      )(newState);
    case "RECEIVED_ACCOMPLISHMENTS":
      return R.pipe(
        R.assoc("error", false),
        R.assoc("loading", false),
        R.assoc("list", action.items)
      )(newState);
    case "RECEIVED_ACCOMPLISHMENTS_ERROR":
      return R.pipe(
        R.assoc("error", true),
        R.assoc("loading", false)
      )(newState);
    default:
      return previousState;
  }
};

export default AccomplishmentReducer;
