import React, {useState} from 'react';
import {useDispatch} from "react-redux";

import {login} from '../../actions/auth';
import SocialLogin from "./SocialLogin";

import Input from "../ui/Input";
import Button from "../ui/Button";
import Link from "../ui/Link";

import AlertIcon from "../../assets/alert-orange.svg";

function Login() {

  const dispatch = useDispatch();

  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');

  return (
    <div className="Login">
      <div className="row">
        <div className="col-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
          <div className={"formBlock"}>
            <div className={"warning row"}>
              <div className={"primary"}>
                <div className={"icon"}>
                  <img alt="alert" src={AlertIcon}/>
                </div>
                <div className={"text"}>
                  <p>Ce site est en cours de développement.</p>
                </div>
              </div>
              <div className={"secondary"}>
                <p>Les données saisies ne seront pas conservées dans le temps.</p>
              </div>
            </div>
            <Input type="text" id="name" placeholder="Identifiant" value={inputEmail} onChange={text => setInputEmail(text)} />
            <Input type="password" id="name" placeholder="Mot de passe" value={inputPassword} onChange={text => setInputPassword(text)} />
            <br/>
            <div className={"buttonsBlock"}>
              <Button onClick={() => dispatch(login(inputEmail, inputPassword))}>Se connecter</Button>
              <Link to="/signup">S'inscrire</Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SocialLogin/>
      </div>
      <hr />
    </div>
  );
}

export default Login;
