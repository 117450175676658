import React, {useState, useRef, useEffect} from 'react';
import {useDispatch} from "react-redux";

import {addAchievement, updateAchievement, validateAchievement} from "../../actions/achievement";
import Checkbox from "../ui/Checkbox";
import AddIcon from "../../assets/add.svg";
import CancelIcon from "../../assets/cancel.svg";
import ConfirmIcon from "../../assets/confirm.svg";
import Button from "../ui/Button";

function AchiNew(props) {

  const titleRef = useRef();
  const descriptionRef = useRef();

  const {
    title: propTitle = "",
    description: propDescription = "",
    isDaily: propIsDaily = false,
    isWeekly: propIsWeekly = false,
    isMonthly: propIsMonthly = false,
    isOneShot: propIsOneShot = false,
  } = props;

  const [title, setTitle] = useState(propTitle);
  const [description, setDescription] = useState(propDescription);
  const [isDaily, setIsDaily] = useState(propIsDaily);
  const [isWeekly, setIsWeekly] = useState(propIsWeekly);
  const [isMonthly, setIsMonthly] = useState(propIsMonthly);
  const [isOneShot, setIsOneShot] = useState(propIsOneShot);
  const [isExpanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const clearAll = () => {
    setExpanded(false);
    setTitle("");
    setDescription("");
    setIsDaily(false);
    setIsWeekly(false);
    setIsMonthly(false);
    setIsOneShot(false);
  };

  const cancelEdition = () => {
    props.stopEdition();
  };

  const updateMode = !!props.uuid;

  const createAchievement = () => {
    if (updateMode) {
      dispatch(updateAchievement(props.uuid, {title, description, isDaily, isWeekly, isMonthly, isOneShot}));
      props.stopEdition();
    } else {
      dispatch(addAchievement({title, description, isDaily, isWeekly, isMonthly, isOneShot}));
    }
    clearAll();
  };

  const disableAll = () => {
    setIsOneShot(false);
    setIsDaily(false);
    setIsWeekly(false);
    setIsMonthly(false);
  };

  const closeModal = () => {
    dispatch({type: "RESET_MODAL"});
  };

  const openModal = () => {
    dispatch({
      type: "SET_MODAL",
      content:
        <div>
          <h1>Récurrence</h1>
          <div className={"row"}>
            <div className={"col-12"}>
              <Button
                onClick={() => {
                  disableAll();
                  setIsOneShot(true);
                  closeModal();
                }}>
                Ponctuelle
              </Button>
            </div>
            <div className={"col-12"}>
              <Button
                onClick={() => {
                  disableAll();
                  setIsDaily(true);
                  closeModal();
                }}>
                Quotidienne
              </Button>
            </div>
            <div className={"col-12"}>
              <Button
                onClick={() => {
                  disableAll();
                  setIsWeekly(true);
                  closeModal();
                }}>
                Hebdomadaire
              </Button>
            </div>
            <div className={"col-12"}>
              <Button
                onClick={() => {
                  disableAll();
                  setIsMonthly(true);
                  closeModal();
                }}>
                Mensuelle
              </Button>
            </div>
          </div>
        </div>
    });
  };

  useEffect(() => {
    // titleRef.current.focus();
  }, []);

  const handleKeyPressed = (input, e) => {
    if (e.charCode === 13) {
      if (input === "title") {
        // descriptionRef.current.focus();
        createAchievement();
        titleRef.current.focus();
      } else if (input === "description") {
        createAchievement();
      }
    }
  };

  const [isBlock2Visible, setBlock2Visible] = useState(false);
  useEffect(() => {
    setBlock2Visible(isExpanded || title.length > 0);
  }, [title, isExpanded]);

  const getRecurrence = () => {
    if (isOneShot) {
      return "Ponctuelle";
    }
    if (isDaily) {
      return "Quotidienne";
    }
    if (isWeekly) {
      return "Hedbomadaire";
    }
    if (isMonthly) {
      return "Mensuelle";
    }
  };

  return (
    <div className={`nopadding newBlock ${isBlock2Visible ? "active" : ""}`}>
      <input
        ref={titleRef}
        className={"title"}
        placeholder="Je dois également penser à..."
        type="text"
        value={title}
        onChange={e => setTitle(e.target.value)}
        onKeyPress={(e) => handleKeyPressed("title", e)}
        required
      /><br/>
      {!updateMode &&
      <img className={"cancel"} alt="cancel" src={CancelIcon} onClick={() => updateMode ? cancelEdition() : clearAll()}/>
      }
      <img className={"add"} alt="add" src={AddIcon} onClick={() => setExpanded(true)}/>
      <div className={isBlock2Visible ? "showBlock2" : "hideBlock2"}>
        {/*<img className={"enter"} alt="enter" src={EnterIcon} onClick={() => setExpanded(true)}/>*/}
        {/*<input*/}
        {/*  ref={descriptionRef}*/}
        {/*  className="description"*/}
        {/*  placeholder="Description"*/}
        {/*  type="text"*/}
        {/*  value={description}onChange={e => setDescription(e.target.value)}*/}
        {/*  onKeyPress={(e) => handleKeyPressed("description", e)}*/}
        {/*/><br/>*/}
        <div className={"hideOnSmallScreen"}>
          <Checkbox checked={isOneShot} size="small" value={isOneShot} onClick={() => {disableAll(); setIsOneShot(!isOneShot);}}>Ponctuelle</Checkbox>
          <Checkbox checked={isDaily} size="small" value={isDaily} onClick={() => {disableAll(); setIsDaily(!isDaily);}}>Quotidienne</Checkbox>
          <Checkbox checked={isWeekly} size="small" value={isWeekly} onClick={() => {disableAll(); setIsWeekly(!isWeekly);}}>Hebdomadaire</Checkbox>
          <Checkbox checked={isMonthly} size="small" value={isMonthly} onClick={() => {disableAll(); setIsMonthly(!isMonthly);}}>Mensuelle</Checkbox>
        </div>
        <div className={"bigblock"}>
          <div className={"hideOnLargeScreen recurrenceBlock"}>
            <Button wrap={true} onClick={() => openModal()}>{getRecurrence() || "Récurrence"}</Button>
          </div>
          <span className={"confirmBlock"} onClick={() => createAchievement()}>
            <img className={"confirm"} alt="confirm" src={ConfirmIcon}/>
            <label className={"confirmText"}>{updateMode ? "Enregistrer" : "Ajouter"}</label>
          </span>
        </div>
        {/*<br/>*/}
        {/*<Button onClick={() => createAchievement()}>Enregister</Button>*/}
      </div>
    </div>
  );
}

export default AchiNew;
