import React from 'react';
import ReactModal from 'react-modal';

import {useDispatch, useSelector} from "react-redux";

function Modal() {
  const dispatch = useDispatch();
  const {content, isVisible} = useSelector(s => s.modalReducer);

  const closeModal = () => {
    dispatch({type: "RESET_MODAL"});
  };

  return (
    <div>
      <ReactModal
        isOpen={isVisible}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className={"customModal"}
        overlayClassName={"modalOverlay"}
      >
        {content}
      </ReactModal>
    </div>
  );
}

export default Modal;
