import React from 'react';
import {useDispatch} from "react-redux";

import Button from "../ui/Button";
import Link from "../ui/Link";

function AchiMenu() {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch({type: "REMOVE_TOKEN"});
  };

  return (
    <div className="menu">
      <Link to="/all">Tous les objectifs</Link>
      <Link to="/daily">Rapport quotidien</Link>
      <Button onClick={logout}>Déconnexion</Button>
    </div>
  );
}

export default AchiMenu;
