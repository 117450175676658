import axios from 'axios';
import * as R from 'ramda';

import { store } from "./store";

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (R.pathEq(['response', 'status'], 401, error)) {
    store.dispatch({type: 'REMOVE_TOKEN'});
    return;
  }
  return Promise.reject(error);
});
