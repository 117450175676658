import * as R from "ramda";

const initialState = {
  list: [],
  loading: false,
  error: false,
  filters: {
    status: "VALIDATED"
  },
};

const AchievementReducer = function(previousState = initialState, action) {
  const newState = R.clone(previousState);
  switch (action.type) {
    case "REQUEST_ACHIEVEMENTS":
      return R.pipe(
        R.assoc("error", false),
        R.assoc("loading", true)
      )(newState);
    case "RECEIVED_ACHIEVEMENTS":
      return R.pipe(
        R.assoc("error", false),
        R.assoc("loading", false),
        R.assoc("list", action.items)
      )(newState);
    case "RECEIVED_ACHIEVEMENTS_ERROR":
      return R.pipe(
        R.assoc("error", true),
        R.assoc("loading", false)
      )(newState);
    case "ADD_ACHIEVEMENT":
      const addingList = newState.list;
      addingList.unshift(action.item);
      return R.assoc("list", addingList, newState);
    case "UPDATE_ACHIEVEMENT":
      const list = newState.list.map((item) => {
        if (item.uuid === action.uuid) {
          item = action.item;
        }
        return item;
      });
      return R.assoc("list", list, newState);
    case "SET_FILTER":
      const newFilters = R.mergeRight(newState.filters, action.filter);
      return R.assoc("filters", newFilters, newState);
    default:
      return previousState;
  }
};

export default AchievementReducer;
