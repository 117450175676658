import React from 'react';

function AchiTag(props) {

  return (
    <div className={`tag ${props.type || ""}`}>{props.label}</div>
  );
}

export default AchiTag;
