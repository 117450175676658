import axios from "axios";
import {format, startOfDay} from "date-fns";

import config from '../config.js';

export const getAchievements = ({limit, sort} = {}) => (dispatch, getState) => {
  dispatch({type: "REQUEST_ACHIEVEMENTS"});
  axios.get(`${config.HOST}/api/goals?status=${getState().achievementReducer.filters.status}`, {withCredentials: true, headers: {limit, sort: sort || "-sort"}})
    .then((res) => {
      dispatch({type: "RECEIVED_ACHIEVEMENTS", items: res.data})
    })
    .catch(() => {
      dispatch({type: "RECEIVED_ACHIEVEMENTS_ERROR"});
    });
};

export const getAccomplishments = () => (dispatch) => {
  dispatch({type: "REQUEST_ACCOMPLISHMENTS"});
  axios.get(`${config.HOST}/api/accomplishments?startDate=${format(new Date(), 'yyyy-MM-dd')}:00:00:00`, {withCredentials: true, headers: {sort: "createdAt"}})
    .then((res) => {
      dispatch({type: "RECEIVED_ACCOMPLISHMENTS", items: res.data})
    })
    .catch(() => {
      dispatch({type: "RECEIVED_ACCOMPLISHMENTS_ERROR"});
    });
};

export const persistReorderedList = (source, destination) => dispatch => {
  axios.post(`${config.HOST}/api/goals/reorder`, {source, destination}, {withCredentials: true})
    .then((response) => {
      // cause an ugly refresh
      // dispatch(getAchievements());
    })
};

export const addAchievement = ({title, description, isDaily = false, isWeekly = false, isMonthly = false, isOneShot = false}) => (dispatch) => {
  dispatch({type: "ADD_ACHIEVEMENT", item: {uuid: "tmp_" + Math.random(), title, description, isDaily, isWeekly, isMonthly, isOneShot}});
  axios.post(`${config.HOST}/api/goals`, {title, description, isDaily, isWeekly, isMonthly, isOneShot}, {withCredentials: true})
    .then((response) => {
      dispatch(getAchievements());
    })
};

export const updateAchievement = (uuid, {title, description, isDaily = false, isWeekly = false, isMonthly = false, isOneShot = false}) => (dispatch) => {
  dispatch({type: "UPDATE_ACHIEVEMENT", uuid, item: {title, description, isDaily, isWeekly, isMonthly, isOneShot}});
  axios.put(`${config.HOST}/api/goals/${uuid}`, {title, description, isDaily, isWeekly, isMonthly, isOneShot}, {withCredentials: true})
    .then((response) => {
      dispatch(getAchievements());
    })
};

export const validateAchievement = (uuid, isDone) => (dispatch) => {
  axios.post(`${config.HOST}/api/goals/${uuid}/validate`, {status: isDone ? "VALIDATED" : "CANCELED"}, {withCredentials: true})
    .then((response) => {
      dispatch(getAchievements());
    })
};

export const removeAchievement = (uuid) => (dispatch) => {
  axios.delete(`${config.HOST}/api/goals/${uuid}`, {withCredentials: true} )
    .then((response) => {
      dispatch(getAchievements());
    })
};

export const archiveAchievement = (uuid) => (dispatch) => {
  axios.post(`${config.HOST}/api/goals/${uuid}/archive`, {}, {withCredentials: true} )
    .then((response) => {
      dispatch(getAchievements());
    })
};

export const unarchiveAchievement = (uuid) => (dispatch) => {
  axios.post(`${config.HOST}/api/goals/${uuid}/unarchive`, {}, {withCredentials: true} )
    .then((response) => {
      dispatch(getAchievements());
    })
};
