import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Link, Redirect, Route, Switch} from "react-router-dom";

import * as R from "ramda";

import CookieConsent from "./CookieConsent";

import AchiList from "./Achievements/AchiList";
import AchiNew from "./Achievements/AchiNew";
import DayView from "./Achievements/DayView";
import AchiMenu from "./Achievements/AchiMenu";
import Login from "./Account/Login";
import Signup from "./Account/Signup";
import Mentions from "./mentions";

import LogoIcon from '../assets/assignment.svg';
import Modal from "./Modal";

function App() {
  const [cookieViewVisible, setCookieViewVisible] = useState(false);
  const token = useSelector(R.path(["userReducer", "token"]));

  return (
    <div className="App">
      <Modal/>
      <Link to="/"><h1><img className={"logo"} alt="logo" src={LogoIcon} />GoalLister</h1></Link>
      {token &&
      <div>
        <AchiMenu/>
        <Switch>
          <Route path="/new" component={AchiNew}/>
          <Route path="/all" component={AchiList}/>
          <Route path="/daily" component={DayView}/>
          <Route path="/mentions" component={Mentions}/>
          <Redirect to="/all"/>
        </Switch>
      </div>
      }
      {!token &&
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route path="/signup" component={Signup}/>
        <Route path="/mentions" component={Mentions}/>
        <Redirect to="/"/>
      </Switch>
      }
      <CookieConsent cookieViewVisible={cookieViewVisible} updateVisibilty={setCookieViewVisible}/>
      <footer>
        <span>© 2020 - MeiKorporation <span aria-label="cat foot print" role="img">🐾</span> </span>
        <Link to={"/mentions"}>Mentions légales</Link>
        <span> - </span>
        <span><a onClick={() => setCookieViewVisible(true)}>Cookies</a></span>
      </footer>
    </div>
  );
}

export default App;
