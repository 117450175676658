const frontUrl = new URL(window.location.toString());

const backUrlMapping = {
  "meikomeow.fr": "http://meikomeow.fr:33333",
  "bfront.lab.corentindesfarges.fr": "https://bback.lab.corentindesfarges.fr",
};

const defaultBackUrl = "http://localhost:33333";

export default {
  "GA_PROPERTY": "UA-35742747-6",
  "GOOGLE_CLIENT_ID": "265488081628-bjovfmltsmm89til2qq2deqafdbebme4.apps.googleusercontent.com",
  "FACEBOOK_APP_ID": "493719351288373",
  "HOST": backUrlMapping[frontUrl.hostname] || defaultBackUrl,
  "FRONT_URL": frontUrl.origin,
};
