import React from 'react';
import {useDispatch} from "react-redux";

import {GoogleLogin} from 'react-google-login';
import {loginOAuth} from "../../actions/auth";

import config from '../../config.js';
import GoogleLogo from '../../assets/google.png';

import "./styles.css";

function SocialLogin() {

  const dispatch = useDispatch();

  const onFailure = (error) => {
    console.error(error)
  };

  return (
    <div className="socialLogin">
      <GoogleLogin
        clientId={config.GOOGLE_CLIENT_ID}
        onSuccess={(r) => dispatch(loginOAuth('google', r))}
        onFailure={onFailure}
        render={renderProps => (
          <img className="network" alt="GoogleLogo" src={GoogleLogo} onClick={renderProps.onClick}/>
        )}
      />
    </div>
  );
}

export default SocialLogin;
