import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as R from "ramda";

import {getAchievements, persistReorderedList} from "../../actions/achievement";
import AchiError from "./AchiError";
import AchiItem from "./AchiItem";
import AchiNew from "./AchiNew";

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

const DDItem = (props) => {
  return (
    <Draggable draggableId={props.uuid} index={props.index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <AchiItem item={props.item} key={props.item.uuid} isDragging={snapshot.isDragging} provided={provided}/>
        </div>
      )}
    </Draggable>
  )
};

function AchiList(props) {
  const dispatch = useDispatch();
  const {list, error, filters} = useSelector((s) => s.achievementReducer);
  const [archivedVisibled, setArchivedVisibled] = useState(R.includes("ARCHIVED", filters.status));

  useEffect(() => {
    dispatch({type: "SET_FILTER", filter: {status: archivedVisibled ? ["VALIDATED", "ARCHIVED"] : "VALIDATED"}})
  }, [archivedVisibled])

  const [columns, setColumns] = useState({
    colNumberOne: {
      id: 'colNumberOne',
      title: 'Ma première colonne',
      items: list
    }
  });
  const currentColumn = columns["colNumberOne"];

  useEffect(() => {
    dispatch(getAchievements({limit: props.limit}));
  }, [archivedVisibled]);

  useEffect(() => {
    setColumns({
      ...columns,
      [currentColumn.id]: {
        id: currentColumn.id,
        title: currentColumn.title,
        items: list
      }
    });
  }, [list]);

  const onDragEnd = ({source, destination, draggableId}) => {
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    dispatch(persistReorderedList(currentColumn.items[source.index].uuid, currentColumn.items[destination.index].uuid))

    const newList = Array.from(currentColumn.items);
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, list.find((i) => i.uuid === draggableId));
    setColumns({
      ...columns,
      [currentColumn.id]: {
        id: currentColumn.id,
        title: currentColumn.title,
        items: newList
      }
    });
  };

  const listStyleOver = {
    borderRadius: 10,
    backgroundColor: "#182d35"
  };

  const listStyleNotOver = {
    backgroundColor: "transparent"
  };

  return (
    <div>
      <div className="row" style={{justifyContent: 'center'}}>
        <div className={`filter ${archivedVisibled ? "" : "disabled"}`} onClick={() => setArchivedVisibled(!archivedVisibled)}>Archives</div>
      </div>
    <div className="achievements row">
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        {error &&
        <AchiError/>
        }
        {!error &&
        <Droppable droppableId={currentColumn.id}>
          {(provided, snapshot) => (
            <div
              style={snapshot.isDraggingOver ? listStyleOver : listStyleNotOver}
              className={"offset-md-0 col-md-12 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6 mainList"}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <AchiNew />
              {currentColumn.items.map((item, index) => <DDItem item={item} key={item.uuid} title={item.title} uuid={item.uuid} index={index}/>)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        }
      </DragDropContext>
    </div>
    </div>
  );
}

export default AchiList;
