import * as R from "ramda";

const initialState = {
  list: [],
  loading: false,
  error: false,
};

const CatReducer = function(previousState = initialState, action) {
  const newState = R.clone(previousState);
  switch (action.type) {
    case "REQUEST_CATS":
      return R.pipe(
        R.assoc("error", false),
        R.assoc("loading", true)
      )(newState);
    case "RECEIVED_CATS":
      return R.pipe(
        R.assoc("error", false),
        R.assoc("loading", false),
        R.assoc("list", action.items)
      )(newState);
    case "RECEIVED_CATS_ERROR":
      return R.pipe(
        R.assoc("error", true),
        R.assoc("loading", false)
      )(newState);
    case "ADD_CAT":
      const list = newState.list;
      list.push(action.data);
      return R.assoc("list", list, newState);
    default:
      return previousState;
  }
};

export default CatReducer;
