import UserReducer from "./user";
import CatReducer from "./cat";
import AchievementReducer from "./achievement";
import AccomplishmentReducer from "./accomplishment";
import ModalReducer from "./modal";

export default {
  modalReducer: ModalReducer,
  userReducer: UserReducer,
  catReducer: CatReducer,
  achievementReducer: AchievementReducer,
  accomplishmentReducer: AccomplishmentReducer,
};
